import React from 'react';
import styled from 'styled-components'

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */

const Logo = ({ fill = "#fff" }: { fill?: string }) => {
  
  return (

<svg width="193" height="19" viewBox="0 0 193 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="143.156" y="0.633026" width="48.6948" height="17.391" fill="#FF4C45"/>
  <path d="M2.71299 7.30421V4.19471H0V14.5458H2.71299V8.89027C3.06777 8.15985 4.17384 6.42771 6.69901 6.42771V3.94428C4.23644 3.94428 3.10951 6.03119 2.71299 7.30421Z" fill={fill}/>
  <path d="M18.1881 4.19471V7.26247C17.6247 5.30077 16.1638 3.94428 13.4926 3.94428C10.237 3.94428 7.79529 6.11467 7.79529 9.39113C7.79529 12.6259 10.237 14.7962 13.3465 14.7962C16.1012 14.7962 17.6247 13.1058 18.1881 11.2276V11.8746C18.1881 13.1267 18.376 14.5458 18.376 14.5458H20.9011V4.19471H18.1881ZM14.223 12.6885C12.0109 12.6885 10.5709 11.3737 10.5709 9.39113C10.5709 7.38769 11.8856 6.05206 14.223 6.05206C16.6438 6.05206 18.1881 7.26248 18.1881 9.39113C18.1881 11.3737 16.6647 12.6885 14.223 12.6885Z" fill={fill}/>
  <path d="M32.5812 4.19471V9.91286C32.2264 10.7268 31.1621 12.605 28.7621 12.605C27.03 12.605 26.0074 12.0206 26.0074 10.0172V4.19471H23.2944V10.4555C23.2944 13.8363 25.6526 14.7962 27.9274 14.7962C30.8908 14.7962 32.1638 12.3963 32.5812 10.9981V14.5458H35.2942V4.19471H32.5812Z" fill={fill}/>
  <path d="M37.9744 0.0208689V14.5458H40.6874V0.0208689H37.9744Z" fill={fill}/>
  <path d="M55.9573 3.94428C53.1817 3.94428 51.6791 5.2799 51.1156 7.24161V6.92857L50.9695 4.19471H48.4026V18.7197H51.1156V11.2485C51.7 13.1267 53.2025 14.7962 55.9573 14.7962C59.025 14.7962 61.5085 12.6676 61.5085 9.39113C61.5085 6.15641 59.0668 3.94428 55.9573 3.94428ZM55.0808 12.6885C52.7226 12.6885 51.22 11.4781 51.1156 9.62069V9.16157C51.2408 7.179 52.9104 6.05206 55.0808 6.05206C57.3972 6.05206 58.7329 7.38769 58.7329 9.39113C58.7329 11.3946 57.2929 12.6885 55.0808 12.6885Z" fill={fill}/>
  <path d="M72.9798 11.1024C72.6459 12.0833 71.4147 12.7302 69.6825 12.7302C67.3452 12.7302 65.9469 11.4989 65.7591 9.78765H75.6511C75.8181 5.84337 73.7311 3.94428 69.6825 3.94428C65.6548 3.94428 63.0252 5.98945 63.0252 9.39113C63.0252 12.8345 65.78 14.7962 69.7451 14.7962C73.272 14.7962 75.2337 13.2519 75.5885 11.1024H72.9798ZM69.5364 6.01032C71.5816 6.01032 72.792 6.88683 72.959 8.30593H65.8843C66.2391 6.99118 67.533 6.01032 69.5364 6.01032Z" fill={fill}/>
  <path d="M80.3202 7.30421V4.19471H77.6073V14.5458H80.3202V8.89027C80.675 8.15985 81.7811 6.42771 84.3063 6.42771V3.94428C81.8437 3.94428 80.7168 6.03119 80.3202 7.30421Z" fill={fill}/>
  <path d="M93.2479 4.19471H90.5766V1.33563H87.8636V4.19471H85.1924V6.23988H87.8636V14.5458H90.5766V6.23988H93.2479V4.19471Z" fill={fill}/>
  <path d="M104.304 11.1024C103.97 12.0833 102.739 12.7302 101.007 12.7302C98.6693 12.7302 97.2711 11.4989 97.0833 9.78765H106.975C107.142 5.84337 105.055 3.94428 101.007 3.94428C96.9789 3.94428 94.3494 5.98945 94.3494 9.39113C94.3494 12.8345 97.1041 14.7962 101.069 14.7962C104.596 14.7962 106.558 13.2519 106.913 11.1024H104.304ZM100.861 6.01032C102.906 6.01032 104.116 6.88683 104.283 8.30593H97.2085C97.5632 6.99118 98.8571 6.01032 100.861 6.01032Z" fill={fill}/>
  <path d="M122.793 12.7928C128.01 12.7928 131.662 10.1633 131.662 6.57379C131.662 3.00516 128.052 0.50086 122.793 0.50086C117.492 0.50086 113.882 2.98429 113.882 6.57379C113.882 10.1633 117.534 12.7928 122.793 12.7928ZM122.793 11.1233C118.744 11.1233 115.76 9.32852 115.76 6.57379C115.76 3.83993 118.723 2.17039 122.793 2.17039C126.821 2.17039 129.784 3.83993 129.784 6.57379C129.784 9.32852 126.8 11.1233 122.793 11.1233ZM119.496 9.49548H121.311V7.7842H123.294C124.525 7.7842 124.859 7.95116 124.859 8.45202V9.49548H126.675V8.34767C126.675 7.70073 126.424 7.28334 125.172 7.13726C126.445 7.05378 126.821 6.44858 126.821 5.48859C126.821 4.21557 125.965 3.56863 124.108 3.56863H119.496V9.49548ZM121.311 6.3651V5.13382H124.129C124.671 5.13382 124.984 5.30077 124.984 5.69729C124.984 6.15641 124.65 6.3651 124.129 6.3651H121.311Z" fill={fill}/>
  <path d="M134.041 16.9666C135.836 16.6119 136.775 15.631 136.775 13.7319V12.1667H133.582V14.5458L134.73 14.7754C134.667 15.7771 133.791 16.2571 133.791 16.2571L134.041 16.9666Z" fill={fill}/>
  <path d="M151.877 3.94428C149.081 3.94428 147.599 5.30077 147.035 7.26247V0H144.322V14.5458H146.827C146.827 14.5458 147.035 13.1267 147.035 11.8746V11.2276C147.599 13.1058 149.122 14.7962 151.877 14.7962C155.028 14.7962 157.428 12.6259 157.428 9.39113C157.428 6.11467 155.091 3.94428 151.877 3.94428ZM151.001 12.6885C148.538 12.6885 147.035 11.3737 147.035 9.39113C147.035 7.26248 148.58 6.05206 151.001 6.05206C153.317 6.05206 154.653 7.38769 154.653 9.39113C154.653 11.3737 153.192 12.6885 151.001 12.6885Z" fill={fill}/>
  <path d="M159.664 0.0208689V14.5458H162.377V0.0208689H159.664Z" fill={fill}/>
  <path d="M171.235 14.7962C175.138 14.7962 177.788 12.918 177.788 9.39113C177.788 5.88511 175.138 3.94428 171.235 3.94428C167.312 3.94428 164.62 5.88511 164.62 9.39113C164.62 12.918 167.312 14.7962 171.235 14.7962ZM171.235 12.7093C169.065 12.7093 167.354 11.5407 167.354 9.39113C167.354 7.24161 169.086 6.03119 171.235 6.03119C173.385 6.03119 175.034 7.26248 175.034 9.39113C175.034 11.5407 173.343 12.7093 171.235 12.7093Z" fill={fill}/>
  <path d="M186.409 4.09036C185.95 3.98601 185.47 3.94428 184.99 3.94428C181.505 3.94428 179.314 5.73902 179.314 8.51463C179.314 10.7059 180.649 12.1667 182.799 12.7093C181.004 12.7302 179.648 13.2102 179.648 14.6502C179.648 15.7771 180.42 16.6119 182.924 16.6119H186.18C187.578 16.6119 188.475 17.0501 188.475 18.344V18.824H191.188V17.864C191.188 15.3597 189.54 14.5667 186.764 14.5667L183.362 14.5458C182.716 14.5458 182.382 14.3163 182.382 13.878C182.382 13.1893 183.216 13.0224 184.99 12.9598C188.455 12.9598 190.604 11.3737 190.604 8.63984C190.604 6.99118 189.268 5.88511 187.828 5.17556C188.955 5.65555 190.541 6.17728 192.044 6.15641V4.06949L186.409 4.09036ZM186.868 4.77904V4.7373C186.973 4.77904 187.077 4.84165 187.202 4.90426C187.098 4.86252 186.973 4.79991 186.868 4.77904ZM184.99 11.0189C183.154 11.0189 181.943 10.1007 181.943 8.53549C181.943 6.97031 183.175 6.05206 184.99 6.05206C186.785 6.05206 187.975 6.99118 187.975 8.53549C187.975 10.1007 186.764 11.0189 184.99 11.0189Z" fill={fill}/>
</svg>
  );

}

export default Logo;
